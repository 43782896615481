@use '../../foundation/mixins';

.splide {
  $root: &;

  &__toggle {
    cursor: pointer;

    &:focus-visible {
      @include mixins.focus-outline;
    }
  }

  &.is-focus-in {
    #{ $root }__toggle:focus {
      @include mixins.focus-outline;
    }
  }
}
